import React from 'react';
import { LogOutSvg } from '../_Common'
import { Text, View, ScrollView, StyleSheet, TouchableOpacity } from 'react-native'
import { logout } from './Logout'
export const MoreOptions = (props) => {
    function LogoutUser() {
        logout()
    }
    return (
        <>
            <ScrollView>
                <ButtunIcon text={{ color: "#f00" }} onPress={LogoutUser} icon={<LogOutSvg width={25} color="#f00" />}>Déconnexion</ButtunIcon>
            </ScrollView>
        </>
    )
}
const ButtunIcon = ({ children, icon, text, onPress, leftIcon }) => {
    return (
        <TouchableOpacity onPress={onPress}>
            <View style={Styles.BtnContainer}>
                <View style={Styles.flex}>
                    {icon}
                    <Text style={[Styles.BtnText, text]}>{children}</Text>
                </View>
                {leftIcon}
            </View>
        </TouchableOpacity>
    )
}
const Styles = StyleSheet.create({
    BtnContainer: {
        backgroundColor: "#FFFFFF",
        flexDirection: "row",
        paddingHorizontal: 15,
        paddingVertical: 20,
        borderBottomColor: "#00000040",
        borderBottomWidth: .5,
        justifyContent: "space-between",
        alignItems: "center"
    },
    flex: {
        flexDirection: "row",
    },
    BtnText: {
        fontWeight: "500",
        fontSize: 17,
        marginLeft: 8
    }
})