import { autotestConstants } from '../constants';
import { RequestService,FunctionService } from '../services';
export const autotestActions = {
    getTest,
    getDetailTest,
    submitAnswer
};
function getTest(){
    return dispatch => {
        dispatch(FunctionService.request(autotestConstants.GET_TEST_REQUEST))
        RequestService.GetRequest('evaluator/testAuto',true)
        .then(res=>{
            dispatch(FunctionService.succes(res.data,autotestConstants.GET_TEST_SUCCESS))
        })
        .catch(err=>{
            dispatch(FunctionService.failure(err.response,autotestConstants.GET_TEST_FAILURE))
        })
    }
}
function getDetailTest(idtest){
    return dispatch => {
        dispatch(FunctionService.request(autotestConstants.GET_DETAIL_TEST_REQUEST))
        RequestService.GetRequest('evaluator/testAuto/detail/'+idtest,true)
        .then(res=>{
            dispatch(FunctionService.succes(res.data,autotestConstants.GET_DETAIL_TEST_SUCCESS))
        })
        .catch(err=>{
            dispatch(FunctionService.failure(err.response,autotestConstants.GET_DETAIL_TEST_FAILURE))
        })
    }
}
function submitAnswer(answer){
    return dispatch => {
        dispatch(FunctionService.request(autotestConstants.SUBMIT_TEST_REQUEST))
        RequestService.PostRequest("answerAutoEval/create",answer,true)
        .then(res=>{
            dispatch(FunctionService.succes(res.data,autotestConstants.SUBMIT_TEST_SUCCESS))
        })
        .catch(err=>{
            dispatch(FunctionService.failure(err.response,autotestConstants.SUBMIT_TEST_FAILURE))
        })
    }
}