import React from 'react'
import {View,Text,StyleSheet} from 'react-native'
const styles=StyleSheet.create({
    container:{
        width:"100%",
        height:"100%",
        flex: 2,
        flexDirection:"column",
        alignItems:"center",
        justifyContent:"center",
        paddingHorizontal:13
    },
    childrenContainer:{
        marginTop:17,
        alignItems:"center"
    }
})
export function CheckElement(props){
    return (
        <View style={styles.container}>
            {props.Icon}
            <View style={styles.childrenContainer}>
                {props.children}
            </View>
        </View>
    )
}