import { StyleSheet } from 'react-native'
export const styles = StyleSheet.create({
    container: {
        backgroundColor: "#FFFFFF",
        marginBottom: 5,
        paddingHorizontal: 20,
        paddingVertical: 10
    },
    spaceAround: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-around"
    },
    alignFlex: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-around",
        alignItems: "center",
        alignSelf: "flex-start",
        marginBottom: 12
    },
    alignItem: {
        flex: 1,
        flexDirection: "row",
        alignItems: "center"
    },
    setSpaceBtwn: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center"
    },
    Title: {
        fontSize: 20.5,
        color: "#252423",
        marginBottom: 15
    },
    titleOption: {
        fontWeight: "600",
        fontSize: 19,
        color: "#252423",
        marginLeft: 10
    },
    textArea: {
        borderColor: "#00000030",
        fontWeight: "600",
        fontSize: 18,
        height: 130,
        borderWidth: 1,
        textAlignVertical: 'top',
        padding: 10,
        borderRadius: 7
    }
});