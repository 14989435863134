import React from 'react'
import { View, Text, StyleSheet, TextInput } from 'react-native'
import { SubmitBtn } from '../AppPages/_Common'
import { Colors } from '../../config'
const styles = StyleSheet.create({
    title: {
        fontWeight: "700",
        fontSize: 25
    },
    inputStyle: {
        borderWidth: 2,
        borderRadius: 5,
        paddingVertical: 11,
        paddingHorizontal: 10,
        borderColor: global.Colors.primary,
        fontSize: 17,
        marginVertical: 5
    },
    flexLogin: {
        flex: 1,
        flexDirection: "column",
        justifyContent: "space-around"
    },
    flexRows: {
        flex: 1,
        flexDirection: "row"
    }
})
export const UpdatePassword = (props) => {
    return (
        <>
            <Text style={styles.title}>Modifier le mot de pass</Text>
            <Text>Modifier votre mot de pass pour acceder a</Text>
            <Text>Votre espace dans la prochaine connexion</Text>
            <View style={styles.flexLogin}>
                <TextInput
                    onChangeText={text => props._handleChange({ value: text, type: "password", varuse: "password" })}
                    style={styles.inputStyle}
                    secureTextEntry={true}
                    placeholder="Nouveau mot de pass"
                />
                <TextInput
                    onChangeText={text => props._handleChange({ value: text, type: "confirm", varuse: "password" })}
                    style={styles.inputStyle}
                    secureTextEntry={true}
                    placeholder="Confirmer Mot de pass"
                />
                <SubmitBtn
                    actionPress={props._handleSubmit}
                    text="Savgarder"
                    fontClr="#fff"
                    bdgClr={global.Colors.primary}
                />
            </View>

        </>
    )
}
export const Login = (props) => {
    return (
        <>
            <Text style={styles.title}>Préparez-vous pour l’aventure !</Text>
            <Text style={{ fontSize: 20 }}>Veuillez saisir vos informations de connexion</Text>
            <View style={styles.flexLogin}>
                <View>
                    {props.error && <Text style={{ color: "#f03d3d", fontSize: 17 }}>Les informations saisies ne sont pas correctes.</Text>}
                    <TextInput
                        onChangeText={text => props._handleChange({ value: text, type: "email", varuse: "user" })}
                        style={styles.inputStyle}
                        placeholder="Email"
                    />
                    <TextInput
                        onChangeText={text => props._handleChange({ value: text, type: "password", varuse: "user" })}
                        style={styles.inputStyle}
                        placeholder="Mot de passe"
                        secureTextEntry={true}
                    />
                </View>
                <SubmitBtn
                    actionPress={props._handleSubmit}
                    text="Connexion"
                    fontClr="#fff"
                    bdgClr={global.Colors.primary}
                />
            </View>
        </>
    )
}