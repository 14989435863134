import React,{Component} from 'react';
import {Text,ScrollView,View,StyleSheet,Alert,Animated} from 'react-native'
import {testevalActions} from '../../../actions'
import {connect} from 'react-redux'
import {Loader,SubmitBtn,CheckElement,CheckIcon,WarningSvg} from '../_Common'
import {ElementList,CandidateTop} from './ElementList'
import {QestionEvalu} from './QestionEvalu'
import {LoaderFake} from '../_Common/LoaderFake';
class AnswerEvaluTest extends Component {
    constructor(props) {
        super(props)
        this.state={
            show: 1,
            nbPage: 10,
            currentSection: 0,
            showalert: false,
            fadeAnim: new Animated.Value(0),
            showalertfillallanswers:false
        }
        this.timeoutfill=0;
        this.refTopScroll=React.createRef()
        this.scrollBack=React.createRef()
        props.getDetailTest(props.match.params.idtest)
        // this.answers = (props.dataDetailEval && Array.isArray(props.dataDetailEval.answers)) ? props.dataDetailEval.answers : []
        this.answers=(props.anwersavedfile&&Array.isArray(props.anwersavedfile.sections))? props.anwersavedfile.sections:(props.dataDetailEval&&Array.isArray(props.dataDetailEval.answers))? props.dataDetailEval.answers:[]
        this.sizeSections=0
        this._handleScrool=this._handleScrool.bind(this)
        this._handleSectionChange=this._handleSectionChange.bind(this)
        this._handleAnswer=this._handleAnswer.bind(this)
        this.handleSubmit=this.handleSubmit.bind(this)
        this.getstorejsonhandle=this.getstorejsonhandle.bind(this)
        this.storebeforeSavesanhdle=this.storebeforeSavesanhdle.bind(this)
        this.RelaodTest=this.RelaodTest.bind(this)
    }
    fadeIn=() => {
        // Animated.timing(this.state.fadeAnim,{{
        //     toValue: 70,
        //     duration: 300
        // },useNativeDriver: true,}).start();

        Animated.timing(this.state.fadeAnim,{
            toValue: 70,
            duration: 300,
            useNativeDriver: true,
        }).start();
    };

    fadeOut=() => {
        Animated.timing(this.state.fadeAnim,{
            toValue: -100,
            useNativeDriver: true,
            duration: 300
        }).start();
    };
    fadeBase=() => {
        Animated.timing(this.state.fadeAnim,{
            toValue: 0,
            useNativeDriver: true,
            duration: 10
        }).start();
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        const {getingDetailEval,dataDetailEval,submitinAnswer,anwersavedfile,getingTestEvalfake}=nextProps
        if(this.props.getingTestEvalfake===0&&getingTestEvalfake===2&&anwersavedfile&&Array.isArray(anwersavedfile.sections)) {
            this.answers=anwersavedfile.sections
            this.sizeSections=anwersavedfile.sections.length
        } else {
            if(this.props.getingDetailEval===0&&getingDetailEval===1&&dataDetailEval&&Array.isArray(dataDetailEval.answers)) {
                this.answers=dataDetailEval.answers
                this.sizeSections=dataDetailEval.sections.length
                if (dataDetailEval.message !== "YES") {                    
                    setTimeout(() => {
                        this.props.fakerefresh()
                    },500);
                }
            }
        }
        if(this.props.submitinAnswer===0&&submitinAnswer===2) {
            Alert.alert("Problème de connexion internet","š'il veut plaît rester dans la page de reponse et essayer de renvoie votre évaluation")
        }
    }
    _handleSectionChange(value) {
        this.scrollBack.scrollTo({x: 0,y: 0})
        this.setState({
            currentSection: this.state.currentSection+value,
            currentGroupe: 0
        })
        this.scrollBack.scrollTo({x: 0,y: 0})
    }
    _handleScrool(e) {
        this.refTopScroll.scrollTo({x: e.nativeEvent.contentOffset.x,y: e.nativeEvent.contentOffset.y,animated: false});
    }
    _handleSectionChose(key) {
        this.scrollBack.scrollTo({x: 0,y: 0})
        this.setState({
            currentSection: key
        })
        this.scrollBack.scrollTo({x: 0,y: 0})
    }
    _handleAnswer(item) {
        let lengDetail=this.answers[this.state.currentSection].groupes[item.groupe].questions[item.qst].detailanswer[item.candi].answerDetail.length
        for(var i=0;i<lengDetail;i++) {
            this.answers[this.state.currentSection].groupes[item.groupe].questions[item.qst].detailanswer[item.candi].answerDetail[i].reponse=false
        }
        this.answers[this.state.currentSection].groupes[item.groupe].questions[item.qst].detailanswer[item.candi].answerDetail[item.dtl].reponse=item.response
    }
    handleSubmit() {
        for(var k=0;k<this.answers.length;k++) {
            let tmpSection=this.answers[k].groupes
            for(var d=0;d<tmpSection.length;d++) {
                let tmpQstion=tmpSection[d].questions
                for(var i=0;i<tmpQstion.length;i++) {
                    for(var j=0;j<tmpQstion[i].detailanswer.length;j++) {
                        let filterAnser=tmpQstion[i].detailanswer[j].answerDetail.filter(elem => elem.reponse)
                        if(filterAnser.length===0) {
                            this.scrollBack.scrollTo({x: 0,y: 0})
                            this.setState({
                                currentSection: k,
                                showalertfillallanswers:true
                            })
                            this.scrollBack.scrollTo({x: 0,y: 0})
                            if(this.timeoutfill) {
                                clearTimeout(this.timeoutfill)
                            };
                            this.timeoutfill=setTimeout(() => {                                
                                this.setState({
                                    ...this.state,
                                    showalertfillallanswers:false
                                })
                            }, 2500);
                            return 0
                        }
                    }
                }
            }
        }
        let resultSubmit={
            evaluation_evaluator_id: this.props.dataDetailEval.evaluation_evaluator_id,
            sections: this.answers
        }
        this.props.submitAnswer(resultSubmit)
    }
    getstorejsonhandle() {
        console.log('getstorejsonhandle ======')
        this.props.getstorejson({idevel: this.props.dataDetailEval.evaluation_evaluator_id})
    }
    storebeforeSavesanhdle() {
        this.setState({
            ...this.state,
            showalert: true
        })
        this.fadeBase()
        this.fadeIn()
        console.log('storebeforeSavesanhdle ======')
        let resultSubmit={
            evaluation_evaluator_id: this.props.dataDetailEval.evaluation_evaluator_id,
            sections: this.answers
        }
        this.props.storebeforeSave({
            idevel: this.props.dataDetailEval.evaluation_evaluator_id,
            data: JSON.stringify(resultSubmit),
            fullname: this.props.dataUser.user.firstname+'_'+this.props.dataUser.user.lastname
        })
        setTimeout(() => {
            this.fadeOut()
            setTimeout(() => {
                this.setState({
                    ...this.state,
                    showalert: false
                })
            },1000);
        },3000);
    }
    RelaodTest() {
        this.props.getDetailTest(this.props.route.params.idtest)
    }

    render() {
        const {getingDetailEval,submitinAnswer,dataDetailEval,getingTestEvalfake}=this.props
        let sections=[]
        let groupes=[]
        let candidates=[]
        let nbCandi=[]
        let indexSticky=[]
        if(getingDetailEval===1&&dataDetailEval) {
            candidates=dataDetailEval.candidates
            sections=dataDetailEval.sections
            nbCandi=dataDetailEval.nbCandidate
            if(dataDetailEval.sections[this.state.currentSection]) {
                groupes=Array.isArray(dataDetailEval.sections[this.state.currentSection].groupes)? dataDetailEval.sections[this.state.currentSection].groupes:[]
                for(var i=0;i<groupes.length;i++) {
                    indexSticky.push(i*2)
                }
            }
        }
        return (
            <View style={{flex: 1,height: "100%",zIndex: -1}}>{
                getingDetailEval===0? <Loader />:
                    getingTestEvalfake===0? <LoaderFake />:
                        (dataDetailEval&&(dataDetailEval.message==="NO"||dataDetailEval.message==="YES"))? dataDetailEval.message==="NO"?
                            <>
                                {
                                    this.state.showalert&&
                                    <Animated.View
                                        style={[
                                            styles.viewalert,
                                            {
                                                bottom: this.state.fadeAnim
                                            }
                                        ]}>
                                        <Text style={{fontSize: 15,fontWeight: '500',color: 'white',letterSpacing: 0.5}}>Enregistré</Text>
                                    </Animated.View>
                                }
                                {
                                    this.state.showalertfillallanswers&&
                                    <View
                                        style={styles.viewalertfillall}>
                                        <Text style={{fontSize: 15,fontWeight: '500',color: 'white',letterSpacing: 0.5}}>Merci de remplir tous les champs avant d'envoyer votre évaluation.</Text>
                                    </View>
                                }
                                {submitinAnswer===0&&<Loader />}
                                <View style={styles.containerSize}>
                                    <View style={{width: "25%"}}>
                                        <View style={styles.goBackContainer}>
                                            <SubmitBtn actionPress={this.props.history.goBack} customStyle={{paddingHorizontal: 15,marginVertical: 12,width: "100%",paddingVertical: 10,borderRadius: 10}} text="Quitter l'évaluation" bdgClr="#F64E6090" />
                                        </View>
                                    </View>
                                    <ScrollView showsHorizontalScrollIndicator={false} horizontal={true} ref={ref => (this.refTopScroll=ref)}>
                                        <View style={styles.containerTop}>
                                            {candidates.map((candi,key) => {
                                                return <CandidateTop key={key} item={{text: candi.firstname+" "+candi.lastname,photo: candi.photo}} />
                                            })}
                                        </View>
                                    </ScrollView>
                                </View>
                                <ScrollView showsVerticalScrollIndicator={false} stickyHeaderIndices={indexSticky} ref={ref => (this.scrollBack=ref)}>
                                    {groupes.map((grp,key2) => {
                                        return [<View key={key2} style={styles.titleGroupe}>
                                            <View>
                                                <Text style={styles.textGroupe}>{grp.groupe_name}</Text>
                                            </View>
                                        </View>,
                                        <View>
                                            {Array.isArray(grp.questions)&&grp.questions.map((qst,key) => {
                                                return <QestionEvalu qst={qst} candidates={candidates}
                                                    answers={this.answers[this.state.currentSection].groupes[key2].questions[key]}
                                                    handleAnswer={this._handleAnswer} nbCandi={nbCandi} key={key}
                                                    storebeforeSavesanhdle={this.storebeforeSavesanhdle}
                                                    groupe={key2} index={key} handleScroll={this._handleScrool} />
                                            })}
                                        </View>
                                        ]
                                    })}
                                    <View style={styles.flexBtn}>
                                        {this.state.currentSection>0&&<SubmitBtn actionPress={() => this._handleSectionChange(-1)} text="Séquence précedent" bdgClr="#2D70F320" fontClr={global.Colors.primary} />}
                                        <SubmitBtn actionPress={this.state.currentSection===(this.sizeSections-1)? this.handleSubmit:() => this._handleSectionChange(1)}
                                            text={this.state.currentSection===(this.sizeSections-1)? "Envoyer":"Séquence suivant"} bdgClr={global.Colors.primary} fontClr="#FFFFFF" />
                                        {/* <SubmitBtn actionPress={this.getstorejsonhandle} text='get' fontClr="#FFFFFF" bdgClr={global.Colors.primary} /> */}
                                        {/* <SubmitBtn actionPress={this.storebeforeSavesanhdle} text='set' fontClr="#FFFFFF" bdgClr={global.Colors.primary} /> */}
                                    </View>
                                </ScrollView>
                                <View style={styles.bottomContain}>
                                    <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}>
                                        {sections.map((section,key) => {
                                            return <ElementList key={key} item={{text: section.section_name,key: key,active: this.state.currentSection===key}} iterableProps={{onPress: () => this._handleSectionChose(key)}} />
                                        })}
                                    </ScrollView>
                                </View>
                            </>:
                            <CheckElement Icon={<CheckIcon width="150" height="150" />}>
                                <Text style={{fontSize: 25,marginTop: 20,marginBottom: 10,fontWeight: "700",maxWidth: "70%",textAlign: "center"}}>Votre évaluation est terminée ! Merci pour votre participation et à très bientôt ! 😀</Text>
                                <SubmitBtn actionPress={() => this.props.history.goBack()} text="Retour à l'accueil" bdgClr="#2D70F320" fontClr={global.Colors.primary} />
                            </CheckElement>:
                            <CheckElement Icon={<WarningSvg width="150" height="150" />}>
                                <Text style={{fontSize: 25,marginTop: 20,marginBottom: 10,fontWeight: "700",maxWidth: "70%",textAlign: "center"}}>Une erreur se produit lors de chargement de test, essayer de le recharger a nouveau</Text>
                                <SubmitBtn actionPress={this.RelaodTest} text="Recharger" bdgClr="#2D70F320" fontClr={global.Colors.primary} />
                            </CheckElement>
            }
            </View>
        )
    }
}
const styles=StyleSheet.create({
    goBackContainer: {
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center"
    },
    bottomContain: {
        backgroundColor: global.Colors.primary
    },
    flexBtn: {
        marginTop: 25,
        marginBottom: 15,
        flexDirection: "row",
        justifyContent: "space-around"
    },
    titleGroupe: {
        flexDirection: "row",
        paddingHorizontal: 30,
        marginBottom: 15,
        paddingVertical: 8,
        backgroundColor: global.Colors.primary,
        shadowColor: "#000000",
        shadowOffset: {
            width: 0,
            height: 5,
        },
        shadowOpacity: 0.3,
        shadowRadius: 10,
        elevation: 6
    },
    textGroupe: {
        fontWeight: "700",
        fontSize: 20,
        textAlign: "center",
        color: "#FFFFFF"
    },
    textQestion: {
        fontSize: 23,
        fontWeight: "700",
        color: "#000000"
    },
    containerQst: {
        paddingVertical: 15,
        paddingHorizontal: 20,
        backgroundColor: "#FFFFFF"
    },
    containerSize: {
        flexDirection: "row",
        backgroundColor: "#FFFFFF",
        paddingVertical:10,
        borderBottomWidth: 2,
        borderBottomColor: global.Colors.primary,
    },
    containerTop: {
        flexDirection: "row",
    },
    elemList: {
        width: "25%"
    },
    optionQst: {
        marginVertical: 10
    },
    viewalert: {
        position: 'absolute',
        // bottom: 70,
        shadowColor: '#000',
        shadowOffset: {width: -2,height: 4},
        shadowOpacity: 0.7,
        shadowRadius: 9,
        elevation: 5,
        right: 50,
        paddingVertical: 15,
        paddingHorizontal: 35,
        borderRadius: 7,
        backgroundColor: '#04AA6D',
        zIndex: 10
    },
    viewalertfillall: {
        position: 'absolute',
        bottom: 70,
        shadowColor: '#000',
        shadowOffset: {width: -2,height: 4},
        shadowOpacity: 0.7,
        shadowRadius: 9,
        elevation: 5,
        right: 50,
        paddingVertical: 15,
        paddingHorizontal: 35,
        borderRadius: 7,
        backgroundColor: 'red',
        zIndex: 10
    }
})
function mapState(state) {
    const {getingDetailEval,dataDetailEval,submitinAnswer,dataSubmit,anwersavedfile,getingTestEvalfake}=state.testeval
    const {dataUser}=state.auth
    return {getingDetailEval,dataDetailEval,submitinAnswer,dataSubmit,anwersavedfile,dataUser,getingTestEvalfake}
}
const actionCreators={
    getDetailTest: testevalActions.getDetailTest,
    submitAnswer: testevalActions.submitAnswer,
    storebeforeSave: testevalActions.storebeforeSave,
    getstorejson: testevalActions.getstorejson,
    fakerefresh: testevalActions.fakerefresh,
    initDetail: testevalActions.initDetail
};

const connectedClass=connect(mapState,actionCreators)(AnswerEvaluTest);
export {connectedClass as AnswerEvaluTest};

