import React, { Component } from 'react';
import { Text, View } from 'react-native'
import { connect } from 'react-redux'
import { testevalActions } from '../../../actions'
import { Loader, CheckElement, CheckIcon, WarningSvg } from '../_Common'
class DetailEvaluTestPage extends Component {
    constructor(props) {
        super(props)
        props.getDetailTest(props.match.params.idtest)
    }
    render() {
        const { getingDetailEval, dataDetailEval } = this.props
        let note = ""
        if (dataDetailEval && typeof dataDetailEval.message === "string") {
            note = dataDetailEval.message
        }
        return (
            <View style={{ flex: 1, height: "100%" }}>
                {getingDetailEval === 0 ? <Loader /> :
                    note === "YES" ?
                        <CheckElement Icon={<CheckIcon width="110" height="110" />}>
                            <Text style={{ fontSize: 25, fontFamily: "Roboto-Bold" }}>Merci pour votre participation, nous éspérons que vous avez passé bon experience 😀</Text>
                        </CheckElement> : <CheckElement Icon={<WarningSvg width="110" height="110" />}>
                            <Text style={{ fontSize: 25, fontFamily: "Roboto-Bold" }}>Nous sommes déçus de ne pas vous voir dans cette évaluation 😔</Text>
                        </CheckElement>
                }</View>
        )
    }
}
function mapState(state) {
    const { getingDetailEval, dataDetailEval } = state.testeval
    return { getingDetailEval, dataDetailEval }
}
const actionCreators = {
    getDetailTest: testevalActions.getDetailTest
};

const connectedClass = connect(mapState, actionCreators)(DetailEvaluTestPage);
export { connectedClass as DetailEvaluTestPage };