import React, { Component } from 'react';
import { Text, ScrollView } from 'react-native'
import { connect } from 'react-redux'
import { CheckElement, CheckIcon } from '../_Common'
class DetailAutoTest extends Component {
    render() {
        return (
            <>
                <ScrollView
                    showsVerticalScrollIndicator={false}
                >
                    <CheckElement Icon={<CheckIcon width="110" height="110" />}>
                        <Text style={{ fontSize: 25, fontWeight: "700" }}>Le test est terminé, veuillez vous référer à votre autre test 😀</Text>
                    </CheckElement>
                </ScrollView>
            </>
        )
    }
}
function mapState(state) {
    return {}
}
const actionCreators = {
};

const connectedClass = connect(mapState, actionCreators)(DetailAutoTest);
export { connectedClass as DetailAutoTest };