import { testevalConstants } from '../constants'
export function testeval(state, action) {
    switch (action.type) {
        case testevalConstants.SUBMIT_TEST_REQUEST_START:
            return {
                ...state,
                getingTestEvalfake: 0,
            }
        case testevalConstants.SUBMIT_TEST_REQUEST_END:
            return {
                ...state,
                getingTestEvalfake: 2,
            }
        case testevalConstants.GET_TEST_REQUEST:
            return {
                ...state,
                getingTestEval: 0,
                dataTestEval: action.payload
            }

        case testevalConstants.GET_TEST_SUCCESS:
            return {
                ...state,
                getingTestEval: 1,
                dataTestEval: action.payload
            }
        case testevalConstants.GET_TEST_FAILURE:
            return {
                ...state,
                getingTestEval: 2,
                dataTestEval: action.payload
            }
        case testevalConstants.GET_DETAIL_TEST_REQUEST:
            return {
                ...state,
                getingDetailEval: 0,
                getingTestEvalfake: 0,

            }
        case testevalConstants.GET_DETAIL_TEST_SUCCESS:
            let result = {
                evaluation_evaluator_id: "",
                candidates: [],
                sections: [],
                nbCandidate: 0,
                answers: [],
                message: "NO"
            }
            if (action.payload && action.payload.message) {
                let dataTest = action.payload.message
                if (dataTest.status_evaluation_candi === "NO") {
                    result.evaluation_evaluator_id = action.payload.message.evaluation_evaluator_id
                    if (Array.isArray(dataTest.candidates)) {
                        result.nbCandidate = dataTest.candidates.length
                        result.candidates = dataTest.candidates
                    }
                    if (dataTest.evaluation && dataTest.evaluation.grid && Array.isArray(dataTest.evaluation.grid.sections)) {
                        result.sections = dataTest.evaluation.grid.sections
                        result.answers = dataTest.evaluation.grid.sections.map(section => {
                            return {
                                section_id: section.grid_section_id,
                                groupes: section.groupes.map(groupe => {
                                    return {
                                        groupe_id: groupe.qestion_groupe_id,
                                        questions: groupe.questions.map(qst => {
                                            let detailsAnser = []
                                            for (var i = 0; i < result.nbCandidate; i++) {
                                                detailsAnser.push({
                                                    candidate_id: dataTest.candidates[i].candidate_id,
                                                    answerDetail: qst.details.map(dtl => {
                                                        return {
                                                            detail_id: dtl.detail_question_id,
                                                            reponse: null
                                                        }
                                                    })
                                                })
                                            }
                                            return {
                                                qestion_id: qst.question_id,
                                                detailanswer: detailsAnser
                                            }
                                        })
                                    }
                                })
                            }
                        })
                    }
                } else {
                    result.message = "YES"
                }
            } else {
                result.message = "ERROR"
            }
            return {
                ...state,
                getingDetailEval: 1,
                getingTestEvalfake: 1,
                anwersavedfile: action.payload.anwersavedfile,
                dataDetailEval: result
            }
        case testevalConstants.GET_DETAIL_TEST_FAILURE:
            return {
                ...state,
                getingDetailEval: 2,
                dataDetailEval: action.payload
            }
        case testevalConstants.SUBMIT_TEST_REQUEST:
            return {
                ...state,
                submitinAnswer: 0
            }
        case testevalConstants.SUBMIT_TEST_SUCCESS:
            let dataReturn = {}
            if (action.payload && action.payload.message === "Test answerd") {
                dataReturn = {
                    dataDetailEval: {
                        ...state.dataDetailEval,
                        message: "YES"
                    }
                }
            } else {
                dataReturn = {
                    dataSubmit: action.payload
                }
            }
            return {
                ...state,
                submitinAnswer: 1,
                ...dataReturn
            }
        case testevalConstants.REMOVE_DETAIL_TEST_REQUEST:
            return {
                ...state,
                getingDetailEval: null,
                dataDetailEval: null
            }

        case testevalConstants.SUBMIT_TEST_FAILURE:
            return {
                ...state,
                submitinAnswer: 2,
                dataSubmit: action.payload
            }
        default:
            return {
                dataDetailEval: {},
                ...state
            }
    }
}