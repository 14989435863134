export const testevalConstants={
    GET_TEST_SUCCESS: 'GET_TEST_EVAL_SUCCESS',
    GET_TEST_FAILURE: 'GET_TEST_EVAL_FAILURE',
    GET_TEST_REQUEST: 'GET_TEST_EVAL_REQUEST',

    SUBMIT_TEST_REQUEST_START: 'SUBMIT_TEST_REQUEST_START',
    SUBMIT_TEST_REQUEST_END: 'SUBMIT_TEST_REQUEST_END',

    GET_DETAIL_TEST_SUCCESS: 'GET_DETAIL_TEST_EVAL_SUCCESS',
    GET_DETAIL_TEST_FAILURE: 'GET_DETAIL_TEST_EVAL_FAILURE',
    GET_DETAIL_TEST_REQUEST: 'GET_DETAIL_TEST_EVAL_REQUEST',

    SUBMIT_TEST_SUCCESS: 'SUBMIT_TEST_EVAL_SUCCESS',
    SUBMIT_TEST_FAILURE: 'SUBMIT_TEST_EVAL_FAILURE',
    SUBMIT_TEST_REQUEST: 'SUBMIT_TEST_EVAL_REQUEST',

    REMOVE_DETAIL_TEST_REQUEST:"REMOVE_DETAIL_TEST_REQUEST"
}