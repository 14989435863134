import { combineReducers } from 'redux';
import { auth } from './auth.reducer'
import { testsubmit, testDetail } from './testsubmit.reducer'
import { autotest } from './autotest.reducer'
import { testeval } from './testeval.reducer'
import { home } from './home.reducer'
const appReducer = combineReducers({
    auth,
    testsubmit,
    autotest,
    testeval,
    home
})
const rootReducer = (state, action) => {
    if (action.type === 'USER_LOGOUT') {
        state = undefined
    }
    return appReducer(state, action)
}

export default rootReducer;