import React from 'react'
import { BackSvg } from './IconsSvg'
import { View, StyleSheet, TouchableOpacity, Text } from 'react-native'
import { useLocation, useHistory } from "react-router-dom";
const styles = StyleSheet.create({
    container: {
        width: "100%",
        height: 55,
        flexDirection: "row",
        justifyContent: 'space-between',
        paddingHorizontal: 15,
        backgroundColor: global.Colors.primary,
        boxShadow: '0px 0px 10px #9c9c9c'
    },
    flexView: {
        height: '100%',
        flexDirection: 'row',
        justifyContent: "flex-start",
        alignItems: 'center'
    },
    titleStyle: {
        fontSize: 20,
        fontWeight: 600,
        color: '#ffffff'
    }
});
const configRoutes = {
    Home: {
        title: 'Bienvenue à la Journée Test d’Affinité'
    },
    More: {
        title: "Plus de fonctionnalités"
    },
    EvaluTest: {
        title: 'Évaluation'
    },
    AutoTest: {
        title: 'Alors, qu’est-ce que vous en pensez ?'
    },
    DetailAutoTest: {
        title: "Information de l'évaluation",
        goBack: true
    },
    DetailEvaluTest: {
        title: "Information de l'évaluation",
        goBack: true
    },
    AnswerAutoTest: {
        title: "Alors, vous en avez pensé quoi ?",
        goBack: true
    },
    AnswerEvaluTest: {
        title: "Réponse d'évaluation",
        goBack: true,
        noHeader: true
    },
}
const CostumTopbar = (props) => {
    const navigation = useHistory();
    const route = useLocation();
    let pathname = route.pathname.split('/')[1]
    let detail = {}
    if (configRoutes[pathname]) {
        detail = configRoutes[pathname]
    }
    return (
        detail.noHeader ? <></> :
            <View style={styles.container}>
                <View style={styles.flexView}>
                    {detail.goBack &&
                        <TouchableOpacity onPress={navigation.goBack}>
                            <View style={{ marginRight: 15, flexDirection: "row", alignItems: "center" }}>
                                <BackSvg width="25" height="25" />
                            </View>
                        </TouchableOpacity>
                    }
                    <Text style={styles.titleStyle}>{detail.title}</Text>
                </View>
            </View>
    )
}
export { CostumTopbar }