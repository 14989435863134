import React, { Component } from 'react';
import { ScrollView, View, TouchableOpacity, RefreshControl } from 'react-native'
import { connect } from 'react-redux'
import { testevalActions } from '../../../actions'
import { BarState, PendingTest, Loader } from '../_Common'
import moment from 'moment'
class EvaluTestPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            attent: true,
            start: true
        }
        props.getTest()
        this._onRefresh = this._onRefresh.bind(this)
    }
    _handlehidden(stateName) {
        let currentState = this.state[stateName]
        this.setState({
            [stateName]: !currentState
        })
    }
    _onRefresh() {
        this.props.getTest()
    }
    render() {
        const { getingTestEval, dataTestEval } = this.props
        var start = null
        var attent = null
        if (getingTestEval === 1 && dataTestEval && dataTestEval.message && !Array.isArray(dataTestEval.message)) {
            start = Array.isArray(dataTestEval.message.start) ? dataTestEval.message.start : null
            attent = Array.isArray(dataTestEval.message.attent) ? dataTestEval.message.attent : null
        }
        return (
            <>
                {getingTestEval === 0 && <Loader />}
                <ScrollView
                    showsVerticalScrollIndicator={false}
                >
                    {(start || attent) && <TouchableOpacity onPress={() => this._handlehidden('start')}>
                        <BarState typeBar="start" active={this.state.pendding} />
                    </TouchableOpacity>}
                    {this.state.start && <View style={this.state.start ? {} : { height: 0, overflow: "hidden" }}>
                        <View>{start && start.map((test, key) => {
                            return <PendingTest key={key} typeTest="start" date={moment(new Date(test.date_evaluation.split(' ')[0])).startOf('day').fromNow()} navigation={this.props.navigation} path="AnswerEvaluTest" idTest={test.jta_evaluation_id} navigation={this.props.navigation} />
                        })}</View>
                        <View>{attent && attent.map((test, key) => {
                            return <PendingTest key={key} typeTest="start" date={moment(new Date(test.date_evaluation.split(' ')[0])).startOf('day').fromNow()} navigation={this.props.navigation} path="AnswerEvaluTest" idTest={test.jta_evaluation_id} navigation={this.props.navigation} />
                        })}</View>
                    </View>}
                </ScrollView>
            </>
        )
    }
}
function mapState(state) {
    const { getingTestEval, dataTestEval } = state.testeval
    return { getingTestEval, dataTestEval }
}
const actionCreators = {
    getTest: testevalActions.getTest
};

const connectedClass = connect(mapState, actionCreators)(EvaluTestPage);
export { connectedClass as EvaluTestPage };