import React, { Component } from 'react';
import { PendingTest, CostumTabBottum, ArrowAcesSvg, Loader } from '../_Common'
import { authActions, homeActions } from '../../../actions'
import { connect } from 'react-redux'
import { Text, StyleSheet, ScrollView, TouchableOpacity, View } from 'react-native'
import moment from "moment";
class HomePage extends Component {
    constructor(props) {
        super(props)
        if (props.getingUser !== 1) {
            props.getUser()
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { getingUser, dataUser, getingHome } = nextProps
        if (this.props.getingUser === 0 && getingUser === 2) {
            this.props.actionLogin(null)
        }
    }
    componentDidMount() {

    }
    render() {
        const { getingUser, dataUser } = this.props
        return (
            <>
                {getingUser === 0 && <Loader />}
                <ScrollView>
                    {getingUser === 1 && dataUser && dataUser.user &&
                        <>
                            <View style={Styles.container}>
                                <View style={{ flexDirection: "column" }}>
                                    <Text style={Styles.titleText}>{"Bien le bonjour, " + dataUser.user.firstname}</Text>
                                </View>
                            </View>
                            <View style={Styles.container}>
                                <Text style={Styles.titleText}>Votre programme</Text>
                                <TouchableOpacity onPress={() => this.props.navigation.navigate('EvaluTest')}>
                                    <View style={Styles.btnGo}>
                                        <Text style={{ fontWeight: "600", fontSize: 20 }}> L’évaluation de vos candidat(e)s</Text>
                                        <ArrowAcesSvg width="24" height="24" />
                                    </View>
                                </TouchableOpacity>
                                <TouchableOpacity onPress={() => this.props.navigation.navigate('AutoTest')}>
                                    <View style={Styles.btnGo}>
                                        <Text style={{ fontWeight: "600", fontSize: 20 }}>Votre auto-évaluation</Text>
                                        <ArrowAcesSvg width="24" height="24" />
                                    </View>
                                </TouchableOpacity>
                            </View>
                        </>}
                </ScrollView>
            </>
        )
    }
}
const Styles = StyleSheet.create({
    container: {
        backgroundColor: "#FFFFFF",
        paddingHorizontal: 12,
        paddingVertical: 15,
        marginVertical: 15,
        marginHorizontal: 15,
        borderRadius: 10,
        shadowColor: "#000000",
        shadowOffset: {
            width: 2,
            height: 2,
        },
        shadowOpacity: 0.1,
        shadowRadius: 4,
        elevation: 5,
    },
    titleText: {
        fontSize: 25,
        fontWeight: "700"
    },
    subText: {
        fontSize: 18,
        marginTop: 15
    },
    btnGo: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "#2D70F320",
        paddingVertical: 15,
        paddingHorizontal: 20,
        borderRadius: 10,
        marginVertical: 10
    }
})
function mapState(state) {
    const { getingUser, dataUser } = state.auth
    return { getingUser, dataUser }
}
const actionCreators = {
    getUser: authActions.getUser
};

const connectedClass = connect(mapState, actionCreators)(HomePage);
export { connectedClass as HomePage };