import axios from 'axios';
import Cookies from 'js-cookie'
var authUser = Cookies.get('c_user')
const URL_ROOT = process.env.REACT_APP_HOST_API
export const RequestService = {
    GetRequest,
    PostRequest,
    PatchRequest,
    DeleteRequest,
    PutRequest,
    RefreshAuth
};

function RefreshAuth(token) {
    authUser = token
}

function GetRequest(url, protect) {
    return axios.get(`${URL_ROOT}${url}`, {
        headers: protect ? { "Authorization": "Bearer " + authUser } : {}
    })
}

function PostRequest(url, data, protect, custom) {
    return axios.post(`${URL_ROOT}${url}`, data, {
        headers: protect ? { "Authorization": "Bearer " + authUser } : {}
    })
}
function PatchRequest(url, data, protect, custom) {
    return axios.patch(`${URL_ROOT}${url}`, data, {
        headers: protect ? { "Authorization": "Bearer " + authUser } : {}
    })
}
function DeleteRequest(url, protect, custom) {
    return axios.delete(`${URL_ROOT}${url}`, {
        headers: protect ? { "Authorization": "Bearer " + authUser } : {}
    })
}
function PutRequest(url, data, protect, custom) {
    return axios.put(`${URL_ROOT}${url}`, data, {
        headers: protect ? { "Authorization": "Bearer " + authUser } : {}
    })
}