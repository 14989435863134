import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger'
import rootReducer from '../reducer'
const LogerModdleware = createLogger()
export default () => {
  let store = createStore(rootReducer,
    applyMiddleware(
      thunkMiddleware,
      LogerModdleware
    ))
  return { store }
}