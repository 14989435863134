import React from 'react'
import { View, ActivityIndicator } from 'react-native'
export const Loader = (props) => {
    return (
        <View style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            minHeight: 100,
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            zIndex: 100,
            backgroundColor: "#00000020"
        }}>
            <ActivityIndicator size={70} color={global.Colors.primary} />
        </View>
    )
}
