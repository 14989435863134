export const autotestConstants = {
    GET_TEST_SUCCESS: 'GET_TEST_AUTO_SUCCESS',
    GET_TEST_FAILURE: 'GET_TEST_AUTO_FAILURE',
    GET_TEST_REQUEST: 'GET_TEST_AUTO_REQUEST',

    GET_DETAIL_TEST_SUCCESS: 'GET_DETAIL_TEST_AUTO_SUCCESS',
    GET_DETAIL_TEST_FAILURE: 'GET_DETAIL_TEST_AUTO_FAILURE',
    GET_DETAIL_TEST_REQUEST: 'GET_DETAIL_TEST_AUTO_REQUEST',

    SUBMIT_TEST_SUCCESS: 'SUBMIT_TEST_AUTO_SUCCESS',
    SUBMIT_TEST_FAILURE: 'SUBMIT_TEST_AUTO_FAILURE',
    SUBMIT_TEST_REQUEST: 'SUBMIT_TEST_AUTO_REQUEST',
};