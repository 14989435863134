import { answerEvaluConstants } from '../constants';
export function testsubmit(state , action) {
    switch (action.type) {
        case answerEvaluConstants.STORE_ANSWERS:
            let currentAnswer=state.dataAnswers
            currentAnswer[action.payload.index]=action.payload.qst
            return {
                dataAnswers:currentAnswer
            }
        case answerEvaluConstants.INIT_ANSWERS:
            return {
                dataAnswers:action.payload
            }
        default:
            return { ...state}
        }
}