import React from 'react'
import { View, StyleSheet, Text } from 'react-native'
import { ArrowSvg } from './IconsSvg'
import { Colors } from '../../../config'
const BarState = (props) => {
    const types = {
        attent: {
            color: "#F88D01",
            bdgColor: "#F88D0140",
            textBar: "Auto-évaluation en attente de déverrouillage par L’Industreet"
        },
        end: {
            color: "#44BE84",
            bdgColor: "#44BE8440",
            textBar: props.textShow ? props.textShow : "Votre soutien"
        },
        start: {
            color: global.Colors.primary,
            bdgColor: "#2D70F340",
            textBar: "Évaluation en cours"
        }
    }
    const styles = StyleSheet.create({
        container: {
            backgroundColor: types[props.typeBar].bdgColor,
            paddingVertical: 7,
            paddingHorizontal: 10,
            flex: 1,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 10
        },
        textStyle: {
            color: types[props.typeBar].color,
            fontSize: 18,
            fontWeight: "700"
        }
    })
    return (
        <View style={styles.container}>
            <Text style={styles.textStyle}>{types[props.typeBar].textBar}</Text>
            <ArrowSvg color={types[props.typeBar].color} style={{ transition: .2, transform: [{ rotate: props.active ? "0deg" : "-90deg" }] }} />
        </View>
    )
}
export { BarState }