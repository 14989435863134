import React, { Component } from 'react';
import { View } from 'react-native'
import { HomePage, AutoTestPage, DetailAutoTest, DetailEvaluTestPage, EvaluTestPage, AnswerAutoTest, AnswerEvaluTest, LoginPage, LoadingApp, MoreOptions } from './component'
import { CostumTabBottum, CostumTopbar } from './component/AppPages/_Common'
import moment from "moment";
import {
    Route,
    Switch,
    Redirect
} from 'react-router-dom';
import { authActions } from './actions'
import { connect } from 'react-redux'
import Cookies from 'js-cookie'
import 'moment/locale/fr'
moment.locale(["fr", 'en'])
class Root extends Component {
    constructor(props) {
        super(props)
        let token = Cookies.get('c_user')
        this.state = {
            authUser: token
        }
        if (props.getingUser !== 1 && token) {
            props.getUser()
        }
        this.refNavigate = React.createRef();
        this.handleToken = this.handleToken.bind(this)
        this.navigateRoot = this.navigateRoot.bind(this)
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { getingUser } = nextProps
        if (this.props.getingUser === 0 && getingUser === 2) {
            Cookies.remove('c_user')
            this.handleToken(null)
            window.location.reload()
        }
    }
    handleToken(auth) {
        this.setState({
            authUser: auth
        })
    }
    navigateRoot(root, data) {
        if (data) {
            this.props.history.push(root + "/" + data.idtest)
        } else {
            this.props.history.push(root)
        }
    }
    render() {
        const { getingUser, dataUser } = this.props
        return (
            <View style={{ position: 'relative', width: '100%', height: '100%' }}>
                {(getingUser === 0) && <LoadingApp />}
                {this.state.authUser ?
                    getingUser === 1 && dataUser && dataUser.user && <>
                        <CostumTopbar />
                        <Switch>
                            <Route path="/Home">
                                <HomePage navigation={{ navigate: this.navigateRoot }} />
                            </Route>
                            <Route path="/AutoTest">
                                <AutoTestPage navigation={{ navigate: this.navigateRoot }} />
                            </Route>
                            <Route path="/EvaluTest">
                                <EvaluTestPage navigation={{ navigate: this.navigateRoot }} />
                            </Route>
                            <Route path="/More">
                                <MoreOptions navigation={{ navigate: this.navigateRoot }} />
                            </Route>
                            <Route path="/DetailAutoTest/:idtest">
                                {props => <DetailAutoTest {...props} navigation={{ navigate: this.navigateRoot }} />}
                            </Route>
                            <Route path="/DetailEvaluTest/:idtest">
                                {props => <DetailEvaluTestPage {...props} navigation={{ navigate: this.navigateRoot }} />}
                            </Route>
                            <Route path="/AnswerAutoTest/:idtest">
                                {props => <AnswerAutoTest {...props} navigation={{ navigate: this.navigateRoot }} />}
                            </Route>
                            <Route path="/AnswerEvaluTest/:idtest">
                                {props => <AnswerEvaluTest {...props} navigation={{ navigate: this.navigateRoot }} />}
                            </Route>
                            <Redirect from="*" to="/Home" />
                        </Switch>
                        <CostumTabBottum />
                    </> :
                    <Switch>
                        <Route path="/Login">
                            <LoginPage {...this.props} actionLogin={this.handleToken} />
                        </Route>
                        <Redirect from="*" to="/Login" />
                    </Switch>
                }
            </View>
        )
    }
}
function mapState(state) {
    const { getingUser, dataUser } = state.auth
    return { getingUser, dataUser }
}
const actionCreators = {
    getUser: authActions.getUser
};

const connectedClass = connect(mapState, actionCreators)(Root);
export { connectedClass as Root };