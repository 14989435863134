import React, { Component } from 'react';
import { Text, ScrollView, View, StyleSheet, TextInput } from 'react-native'
import { connect } from 'react-redux'
import { autotestActions } from '../../../actions'
import { Loader, SubmitBtn, CheckElement, CheckIcon, WaitingSvg } from '../_Common'
class AnswerAutoTest extends Component {
    constructor(props) {
        super(props)
        this.state = {
            answers: [],
            idTest: null,
            crntQestion: 0
        }
        this.lengthQestion = 0
        this.handleAnswer = this.handleAnswer.bind(this)
        this.handlePosition = this.handlePosition.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        props.getDetailTest(props.match.params.idtest)
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { getingDetail, detailSelfTest } = nextProps
        if (this.props.getingDetail === 0 && getingDetail === 1 && detailSelfTest.test_id) {
            this.state.answers = detailSelfTest.answers
            this.state.idTest = detailSelfTest.test_id
            this.lengthQestion = detailSelfTest.answers.length
        }
    }
    handleAnswer(value) {
        let answers = this.state.answers
        answers[this.state.crntQestion].answer = value
        this.setState({
            answers: answers
        })
    }
    handlePosition(value) {
        this.setState({
            crntQestion: this.state.crntQestion + value
        })
    }
    handleSubmit() {
        let submit = {
            test_auto_id: this.state.idTest,
            answers: this.state.answers
        }
        this.props.submitAnswer(submit)
    }
    render() {
        const { getingDetail, detailSelfTest, submitingTest } = this.props
        let textQestion = "", answer = ""
        if (this.state.answers[this.state.crntQestion]) {
            textQestion = this.state.answers[this.state.crntQestion].textQst
            answer = this.state.answers[this.state.crntQestion].answer
        }
        return (
            <>
                {submitingTest === 0 && <Loader />}
                {getingDetail === 0 ? <Loader /> :
                    <View style={{ height: "100%", padding: 15, backgroundColor: "#FFFFFF" }}>
                        {detailSelfTest.test_id === "waiting" ?
                            <CheckElement Icon={<WaitingSvg width="110" height="110" />}>
                                <Text style={{ fontSize: 20, fontWeight: "700", textAlign: "center" }}>Restons concentrés sur le moment, on se penchera dessus en fin de journée 😀</Text>
                            </CheckElement> : detailSelfTest.test_id === "submited" ?
                                <CheckElement Icon={<CheckIcon width="110" height="110" />}>
                                    <Text style={{ fontSize: 20, fontWeight: "700" }}>Merci pour votre participation à notre grande aventure ! En espérant vous revoir, bonne fin de journée ! 😀</Text>
                                </CheckElement> : <>
                                    <View>
                                        <View style={styles.topTextView}>
                                            <Text style={styles.textTop}>Question {this.state.crntQestion + 1} /</Text>
                                            <Text style={{ fontSize: 20, color: "#8d949e" }}>{this.lengthQestion}</Text>
                                        </View>
                                    </View>
                                    <Text style={styles.textAnswer}>{textQestion}</Text>
                                    <ScrollView
                                        showsVerticalScrollIndicator={false}
                                    >
                                        <TextInput
                                            onChangeText={text => this.handleAnswer(text)}
                                            value={answer}
                                            style={styles.textArea}
                                            placeholder="Écrire votre texte ici ..."
                                            multiline={true}
                                            numberOfLines={10}
                                        />
                                        <View style={styles.flexBtn}>
                                            {(this.state.crntQestion > 0 && this.lengthQestion > 1) && <SubmitBtn actionPress={() => this.handlePosition(-1)} text="Précedent" bdgClr="#2D70F320" fontClr={global.Colors.primary} />}
                                            <SubmitBtn actionPress={(this.state.crntQestion === (this.lengthQestion - 1)) ? this.handleSubmit : () => this.handlePosition(1)} text={(this.state.crntQestion === (this.lengthQestion - 1)) ? "Envoyer" : "Suivant"} bdgClr={global.Colors.primary} fontClr="#FFFFFF" />
                                        </View>
                                    </ScrollView></>}
                    </View>}
            </>
        )
    }
}
const styles = StyleSheet.create({
    textTop: {
        fontWeight: "700",
        fontSize: 25,
        color: "#8d949e"
    },
    topTextView: {
        borderBottomWidth: 1,
        paddingVertical: 10,
        marginTop: 35,
        marginBottom: 20,
        paddingHorizontal: 20,
        borderBottomColor: "#00000020",
        flex: 1,
        flexDirection: "row",
        alignItems: "flex-end"
    },
    textAnswer: {
        fontSize: 27,
        color: "#252423",
        fontWeight: "700",
        marginBottom: 20
    },
    flexBtn: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-around"
    },
    columFlex: {
        flex: 1,
        flexDirection: "column",
        justifyContent: "space-between"
    },
    textArea: {
        borderColor: "#2D70F380",
        fontWeight: "600",
        backgroundColor: "#FFFFFF",
        fontSize: 21,
        height: 200,
        width: "96%",
        marginHorizontal: "2%",
        borderWidth: 2,
        textAlignVertical: 'top',
        padding: 15,
        borderRadius: 7
    }
})
function mapState(state) {
    const { getingDetail, detailSelfTest, submitingTest, dataSubmit } = state.autotest
    return { getingDetail, detailSelfTest, submitingTest, dataSubmit }
}
const actionCreators = {
    getDetailTest: autotestActions.getDetailTest,
    submitAnswer: autotestActions.submitAnswer
};

const connectedClass = connect(mapState, actionCreators)(AnswerAutoTest);
export { connectedClass as AnswerAutoTest };