import React, { Component } from 'react';
import { Provider } from "react-redux";
import configureStore from './App/config/configureStore'
import { Root } from './App/index'
import {
  Router
} from 'react-router-dom';
import { createBrowserHistory } from 'history';
export const history = createBrowserHistory();
const { store } = configureStore();
class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router history={history}>
          <Root history={history} />
        </Router>
      </Provider>
    );
  }
}

export default App;
