import React, { Component } from 'react';
import { ScrollView, View, TouchableOpacity, RefreshControl } from 'react-native'
import { connect } from 'react-redux'
import { autotestActions } from '../../../actions'
import { BarState, PendingTest, Loader } from '../_Common'
import moment from "moment";
class AutoTestPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            attent: true,
            start: true
        }
        this._onRefresh = this._onRefresh.bind(this)
        props.getTest()
    }
    componentDidMount() {
    }
    _handlehidden(stateName) {
        let currentState = this.state[stateName]
        this.setState({
            [stateName]: !currentState
        })
    }
    _onRefresh() {
        this.props.getTest()
    }
    render() {
        const { getingTestSelf, dataTestSelf } = this.props
        var start = null
        var attent = null
        if (getingTestSelf === 1 && dataTestSelf && dataTestSelf.message && !Array.isArray(dataTestSelf.message)) {
            start = Array.isArray(dataTestSelf.message.start) ? dataTestSelf.message.start : null
            attent = Array.isArray(dataTestSelf.message.attent) ? dataTestSelf.message.attent : null
        }
        return (
            <>
                {getingTestSelf === 0 && <Loader />}
                <ScrollView
                    showsVerticalScrollIndicator={false}
                    refreshControl={
                        <RefreshControl
                            colors={["#2D70F3"]}
                            refreshing={!this.firstLoad && getingTestSelf === 0}
                            onRefresh={() => this._onRefresh()}
                        />
                    }
                >
                    {start && <TouchableOpacity onPress={() => this._handlehidden('start')}>
                        <BarState typeBar="start" active={this.state.pendding} />
                    </TouchableOpacity>}
                    {start && this.state.start && <View style={this.state.start ? {} : { height: 0, overflow: "hidden" }}>
                        {start.map((test, key) => {
                            return <PendingTest key={key} typeTest="start" navigation={this.props.navigation} path="AnswerAutoTest" idTest={test.test_auto_id} time="" date={moment(new Date(test.dateautotest.split(' ')[0])).startOf('day').fromNow()} type="1" navigation={this.props.navigation} />
                        })}
                    </View>}
                    {attent && <TouchableOpacity onPress={() => this._handlehidden('attent')}>
                        <BarState typeBar="attent" active={this.state.pendding} />
                    </TouchableOpacity>}
                    {attent && this.state.attent && <View style={this.state.attent ? {} : { height: 0, overflow: "hidden" }}>
                        {attent.map((test, key) => {
                            return <PendingTest key={key} typeTest="attent" navigation={this.props.navigation} path="AnswerAutoTest" idTest={test.test_auto_id} time="" date={moment(new Date(test.dateautotest.split(' ')[0])).startOf('day').fromNow()} type="1" navigation={this.props.navigation} />
                        })}
                    </View>}
                </ScrollView>
            </>
        )
    }
}
function mapState(state) {
    const { getingTestSelf, dataTestSelf } = state.autotest
    return { getingTestSelf, dataTestSelf }
}
const actionCreators = {
    getTest: autotestActions.getTest
};

const connectedClass = connect(mapState, actionCreators)(AutoTestPage);
export { connectedClass as AutoTestPage };