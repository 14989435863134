import React,{Component} from 'react'
import {StyleSheet,View,Text,ScrollView} from 'react-native'
import {ElementCandi,ChooseAnswer,TextAnswer} from './ElementList'
class QestionEvalu extends Component {
    constructor(props) {
        super(props)
        let qst=props.qst? props.qst:{}
        this.state={
            titleQst: qst.text,
            type: qst.typequestion,
            qst_id: qst.question_id,
            index: props.index+1,
            details: Array.isArray(qst.details)? qst.details:[],
            nbCandidate: props.nbCandi,
            groupe: props.groupe,
            onfocustext: '',
        }
        this.timeout=0;
        this.answers=props.answers.detailanswer

        this._handlePress=this._handlePress.bind(this)
        this._handleText=this._handleText.bind(this)
        this.onFocusHandle=this.onFocusHandle.bind(this)
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        const {qst,answers,index,groupe}=nextProps
        if(this.props.question_id!==qst.question_id) {
            this.answers=answers.detailanswer
            this.setState({
                titleQst: qst.text,
                type: qst.typequestion,
                qst_id: qst.question_id,
                index: index+1,
                details: Array.isArray(qst.details)? qst.details:[],
                groupe: groupe
            })
        }
    }
    _handlePress(elem) {
        let detail=this.answers[elem.candi].answerDetail
        detail[elem.dtl].reponse=!elem.active
        this.answers[elem.candi].answerDetail=detail
        this.props.handleAnswer({candi: elem.candi,response: !elem.active,qst: this.props.index,dtl: elem.dtl,groupe: this.state.groupe})
        this.setState({state: this.state})
        this.props.storebeforeSavesanhdle()
    }
    onFocusHandle(txt){
        this.setState({...this.state,onfocustext: txt})
    }
    _handleText(elem,text) {
        let detail=this.answers[elem.candi].answerDetail
        detail[elem.dtl].reponse=!elem.active
        this.answers[elem.candi].answerDetail=detail
        this.props.handleAnswer({candi: elem.candi,response: text,qst: this.props.index,dtl: elem.dtl,groupe: this.state.groupe})
        this.setState({state: this.state})

        if(this.timeout) {
            clearTimeout(this.timeout)
        };
        if(this.state.onfocustext!==text) {
            this.timeout=setTimeout(() => {
                this.setState({...this.state,onfocustext: text})
                this.props.storebeforeSavesanhdle()
            },10000);
        }
    }
    render() {
        return (
            <>
                <View style={styles.containerQst}>
                    <Text style={styles.textQestion}>{this.state.index+"/ "+this.state.titleQst}</Text>
                </View>
                <View style={{flexDirection: 'row'}}>
                    <View style={{width: "25%",justifyContent: "space-around"}}>
                        {this.state.type==="libre"? this.props.candidates.map((candi,key) => {
                            return <ElementCandi key={key} item={{text: candi.firstname+" "+candi.lastname}} />
                        }):this.state.details.map((dtl,key) => {
                            return <ElementCandi key={key} item={{text: dtl.detailtext}} />
                        })}
                    </View>
                    <View style={{width: "75%"}}>
                        <ScrollView horizontal={true} onScroll={(e) => this.props.handleScroll(e)} contentContainerStyle={{flexDirection: "column",justifyContent: "space-around"}} showsHorizontalScrollIndicator={false}>
                            {this.state.type==="libre"?
                                this.state.details.map((elem,key) => {
                                    return <View key={key} style={{flexDirection: this.state.type==="libre"? "column":"row"}}>
                                        {this.answers.map((dtl,key2) => {
                                            return <TextAnswer key={key2+key} item={{qst: this.props.index,dtl: key,candi: key2,text: this.answers[key2].answerDetail[key].reponse}}
                                                action={this._handleText} 
                                                onfocus={this.onFocusHandle}
                                                blur={(e) => {
                                                    if(this.state.onfocustext!==e.nativeEvent.text) {
                                                        if(this.timeout) {
                                                            clearTimeout(this.timeout)
                                                        }
                                                        this.props.storebeforeSavesanhdle()
                                                    }
                                                }} />
                                        })}
                                    </View>
                                })
                                :this.state.details.map((elem,key) => {
                                    return <View key={key} style={{flexDirection: "row"}}>
                                        {this.answers.map((dtl,key2) => {
                                            return <ChooseAnswer key={key2+key} item={{qst: this.props.index,dtl: key,candi: key2,active: this.answers[key2].answerDetail[key].reponse}} action={this._handlePress} />
                                        })}
                                    </View>
                                })}
                        </ScrollView>
                    </View>
                </View>
            </>
        )
    }
}
const styles=StyleSheet.create({
    textQestion: {
        fontSize: 20,
        fontWeight: "700",
        color: "#000000"
    },
    containerQst: {
        paddingVertical: 10,
        paddingHorizontal: 20,
        backgroundColor: "#FFFFFF",
        shadowColor: "#000000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: .1,
        shadowRadius: 6,
    }
})
export {QestionEvalu}