import { homeConstants } from '../constants';
export function home(state , action) {
    switch (action.type) {
        case homeConstants.GET_HOME_TEST_REQUEST:
            return {
                getingHome:0
            }
        case homeConstants.GET_HOME_TEST_SUCCESS:
            let firstTest=null
            let otherTests=[]
            if(action.payload && Array.isArray(action.payload.tests)){
                firstTest=action.payload.tests[0]
                otherTests=action.payload.tests.slice(1,action.payload.tests.length)
            }
            return {
                getingHome:1,
                firstTest:firstTest,
                otherTest:otherTests
            }
        case homeConstants.GET_HOME_TEST_FAILURE:
            return {
                getingHome:2,
                erro:action.payload
            }
        default:
            return { 
                ...state
            }
        }
}