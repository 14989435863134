import React from 'react'
import { HomeSvg, EvaluSvg, AutoSvg, MoreSvg } from './IconsSvg'
import { View, StyleSheet, TouchableOpacity, Text } from 'react-native'
import { useHistory, useLocation } from "react-router-dom";
const styles = StyleSheet.create({
    container: {
        width: "100%",
        height: 62,
        backgroundColor: "#ffffff",
        shadowColor: "#000000",
        shadowOffset: {
            width: 5,
            height: 20,
        },
        shadowOpacity: 0.7,
        shadowRadius: 10,
        elevation: 10,
    },
    flexView: {
        height: "100%",
        flexDirection: 'row',
        justifyContent: "space-around",
        alignItems: 'center',
    },
    elementBar: {
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
    },
    textAction: {
        fontWeight: "700"
    }
});
const CostumTabBottum = (props) => {
    const navigation = useHistory();
    const route = useLocation()
    return (
        ['/EvaluTest', '/Home', '/AutoTest', '/More'].indexOf(route.pathname) >= 0 ?
            <View style={styles.container}>
                <View style={styles.flexView}>
                    <TouchableOpacity onPress={() => navigation.push('/Home')}>
                        <View style={styles.elementBar}>
                            <HomeSvg color={route.pathname === "/Home" ? global.Colors.primary : global.Colors.iconColor} />
                            <Text style={[styles.textAction, { color: route.pathname === "/Home" ? global.Colors.primary : global.Colors.iconColor }]}>Accueil</Text>
                        </View>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => navigation.push('/EvaluTest')}>
                        <View style={styles.elementBar}>
                            <EvaluSvg color={route.pathname === "/EvaluTest" ? global.Colors.primary : global.Colors.iconColor} />
                            <Text style={[styles.textAction, { color: route.pathname === "/EvaluTest" ? global.Colors.primary : global.Colors.iconColor }]}>Évaluation</Text>
                        </View>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => navigation.push('/AutoTest')}>
                        <View style={styles.elementBar}>
                            <AutoSvg color={route.pathname === "/AutoTest" ? global.Colors.primary : global.Colors.iconColor} />
                            <Text style={[styles.textAction, { color: route.pathname === "/AutoTest" ? global.Colors.primary : global.Colors.iconColor }]}>Honnêteté</Text>
                        </View>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => navigation.push('/More')}>
                        <View style={styles.elementBar}>
                            <MoreSvg color={route.pathname === "/More" ? global.Colors.primary : global.Colors.iconColor} />
                            <Text style={[styles.textAction, { color: route.pathname === "/More" ? global.Colors.primary : global.Colors.iconColor }]}>Plus</Text>
                        </View>
                    </TouchableOpacity>
                </View>
            </View> : <></>
    )
}
export { CostumTabBottum }